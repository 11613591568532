import { Linking, Platform } from 'react-native'

// Web Functions
const formattingHttpUrl = (url: string): string => {
  const protocol = url.split(':')?.[0]
  if (protocol !== 'https' && protocol !== 'http') {
    return `http://${url}`
  }
  return url
}

const useOpenUrl = () => {
  const openUrl = async (urlString, newWindow = false) => {
    if (urlString?.startsWith?.('mailto:')) {
      if (await Linking.canOpenURL(urlString)) {
        Linking.openURL(urlString)
      }
      return
    }

    return Platform.OS === 'web'
      ? newWindow
        ? window.open(formattingHttpUrl(urlString), '_blank')
        : window.open(formattingHttpUrl(urlString), '_self')
      : Linking.openURL(urlString)
  }
  return openUrl
}

export default useOpenUrl
