import { gql, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { View, ActivityIndicator, Linking } from 'react-native'
import styled from 'styled-components'
import useOpenUrl from '../hooks/useOpenUrl'

const Container = styled(View)`
  flex: 1;
  justify-content: center;
  align-items: center;
`

const query = gql`
  query unshortenUrl($shortUrl: String!) {
    unshortenUrl(shortUrl: $shortUrl)
  }
`
const RedirectScreen = () => {
  const [shortUrl, setShortUrl] = useState<null | string>('')

  const openUrl = useOpenUrl()
  const { data } = useQuery(query, { variables: { shortUrl }, skip: !shortUrl })

  useEffect(() => {
    Linking.getInitialURL().then(url => setShortUrl(url))
  }, [])

  useEffect(() => {
    if (data?.unshortenUrl) {
      openUrl(data?.unshortenUrl, false)
    }
  }, [data?.unshortenUrl])
  return (
    <Container style={{ flex: 1 }}>
      <ActivityIndicator style={{ flex: 1 }} size="large" color="#006890" />
    </Container>
  )
}

export default RedirectScreen
