const light = {
  appBackground: '#F1F5FC',
  danger: '#ED435C',
  primary: '#404040',
  secondary: '#26B3EF',
  imageBackgroundColor: '#666666',
  logo_onAppBackground: 'rgba(196,196,196,0.8)',
  inputBackground: 'rgba(229, 229, 229, 0.54)',
  inputText: 'rgba(0, 0, 0, 0.74)',
  inputBorder: 'rgba(229, 229, 229, 0.54)',
  inputTextError: 'rgba(230, 0, 34, 0.54)',
  inputBorderError: 'rgba(230, 0, 34, 0.54)',
  placeholderText: 'rgba(0, 0, 0, 0.34)',
  buttonText: '#FFF',
  buttonBackground: '#006890',
  buttonBorder: 'transparent',
  headerText: '#FFF',
  subduedIcon: '#A8A8A8',
  activeTopic: '#318AAC',
  oneDegree: '#EE6C83',
  borderColor: '#D9D9D9',
  purple: '#8A66C7',

  brightPrimary: '#20C1FF',
  accent: '#cb7a16',
  background: '#FFF',
  text: '#333333',
  midGray: '#D0D0D0',
  lightBlue: '#b3d2df',
  gray3: '#828282',
  gray4: '#BDBDBD',
  gray5: '#ECECEC',
  gray6: '#B2B2B2',
  gray7: '#8C8C8C',
  gray8: '#F2F2F2',
  border: '#eee',
  attention: '#F35F75',
  enabled: '#006890',
  disabled: '#A4A6A4',
  success: '#33A51F',
  info: '#06A4CC',
  buttonHighlight: '#06A4CC',
  tagBackground: '#DAE8F8',
  warning: '#FCDA00'
}

const dark = {
  ...light,
  appBackground: '#000'
}

export default { light, dark }
