import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import getApiUrl from './utils/getApiUrl'

const setAuthContext = async (headers: any) => {
  const appId = { 'App-Id': 'short-url-redirector' }
  return {
    headers: { ...headers, ...appId }
  }
}

const authLink = setContext((_, { headers }) => setAuthContext(headers))

const httpLink = createHttpLink({
  uri: getApiUrl('http')
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  name: 'Short URL Redirector',
  cache: new InMemoryCache()
})

export default client
