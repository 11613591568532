import React from 'react'
import Providers from './providers'
import RedirectScreen from '../screens/RedirectScreen'

const AppRoot = () => {
  return (
    <Providers>
      <RedirectScreen />
    </Providers>
  )
}

export default AppRoot
