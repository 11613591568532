import React from 'react'
import { StatusBar } from 'expo-status-bar'
import { I18nextProvider } from 'react-i18next'
import { ApolloProvider } from '@apollo/client'
import i18n from '../../i18n'
import ThemeProvider from './ThemeProvider'
import API from '../../API'

const Providers = ({ children }: any) => {
  return (
    <ThemeProvider>
      <I18nextProvider i18n={i18n}>
        <ApolloProvider client={API}>{children}</ApolloProvider>
        <StatusBar />
      </I18nextProvider>
    </ThemeProvider>
  )
}

export default Providers
